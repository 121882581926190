@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

/* Fix Android */
body {
    -webkit-animation: bugfix infinite 1s;
}

@-webkit-keyframes bugfix {
    from {
        padding: 0;
    }
    to {
        padding: 0;
    }
}

/*
<div class="checkbox-btn">
    <input id="checkbox1" type="checkbox">
    <label for="checkbox1">
        Default
    </label>
</div>
*/

$ch-color: #424242;
$ch-selected-color: #000000;
$ch-disabled-color: #787878;

.checkbox-btn,
.radio-btn {
    //position: relative;
    padding-left: 8px;
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        opacity: 0; /* IE<9 graceful degradation */
        z-index: 1;
    }
    label {
        display: inline-block;
        line-height: 24px; /* ::after height+borders */
        vertical-align: middle;
        position: relative;
        padding-left: 22px;
        cursor: pointer;
        user-select: none; /* iOS =< 6 bug fix*/
        color: $ch-color;
        &::after {
            position: absolute;
            content: "";
            width: 12px;
            height: 12px;
            left: 9px;
            top: 0px;
            margin-left: -18px;
            background-color: white;
            border: 3px solid $ch-color;
            @include border-radius(3px);
            cursor: pointer;
        }
        &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 3px;
            top: 5px;
            left: 14px;
            margin-left: -18px;
            border: 3px solid $ch-selected-color;
            border-top: none;
            border-right: none;
            background: transparent;
            @include transform(rotate(-45deg));
            opacity: 0;
            -webkit-transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
            z-index: 1;
        }
        &:active {
            &::before {
                opacity: 0.3;
            }
        }
    }
    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {
        color: $ch-selected-color;
        &::after {
            border: 3px solid $ch-selected-color;
        }
        &::before {
            opacity: 1;
        }
    }
    input[type="checkbox"]:disabled + label,
    input[type="radio"]:disabled + label {
        color: $ch-disabled-color;
        cursor: default;
        &::after {
            border: 3px solid $ch-disabled-color;
            background-color: #ffffff;
        }
        &::before {
            display: none;
        }
    }
}

.radio-btn {
    label {
        &::after {
            top: 12px;
            @include border-radius(50%);
        }
        &::before {
            width: 8px;
            height: 8px;
            border: none;
            top: 17px;
            left: 14px;
            @include border-radius(50%);
            background-color: #000000;
            @include transform(rotate(0deg));
        }
    }
}
