.nav-link {
  padding-left: 15px;
  -webkit-transition: color 400ms ease;
  transition: color 400ms ease;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #eee;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.nav-link:hover {
  color: #fff;
}

.nav-link.w--current {
  color: #39332d;
}

.logo {
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-logo {
  display: none;
}

.navbar-container {
  padding-top: 5px;
}

.menu-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #66a3d4;
}

.footer-credits {
  margin-top: 20px;
  color: #39332d;
  font-size: 12px;
  text-align: center;
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  -ms-grid-columns: 1fr 0.5fr 0.5fr 0.75fr 1fr;
  grid-template-columns: 1fr 0.5fr 0.5fr 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #eee;
}

.footer-link {
  display: block;
  margin-bottom: 10px;
  opacity: 0.9;
  color: #39332d;
  font-size: 13px;
  line-height: 1em;
  text-align: left;
  text-decoration: none;
}

.footer-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .menu-button {
    color: #fff;
  }

  .menu-button.w--open {
    background-color: #dde3eb;
    color: #39332d;
  }

  .nav-link {
    width: 100%;
    color: #39332d;
  }

  .nav-link:hover {
    color: #66a3d4;
  }

  .nav-link.w--current {
    color: #66a3d4;
  }

  .nav-menu {
    background-color: #dde3eb;
  }

  .book-now-container {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}

@media screen and (max-width: 767px) {
  .container-3 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .footer-link {
    margin-bottom: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 479px) {
  .brand {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .menu-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
}