.tabs_ritiro {
  float:left;
  position: relative;
  width: 100%;
  height:340px;
  overflow:hidden;
}

.tabs_ritiro nav {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 33.333%;
  height: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tabs_ritiro nav a {
  text-align: center;
  width: 100%;
  cursor: pointer;
 
  line-height:40px;
  background: #f7f7f7;
  border:1px solid #fff; 
   box-sizing:border-box;
}

.tabs_ritiro nav a:hover,
.tabs_ritiro nav a.selected {
  background: $base-blue;
 
}

.tabs_ritiro .data_content {
  position: absolute;
  top: 0px;
  left: 33.333%;
 background:#f7f7f7;
width:0;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.1s linear 0s;
}

.tabs_ritiro .data_content.visible {
  padding: 0px;
  width: 66.666%;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.data_content ul{list-style:none; margin:0; padding:0; float:left; width:100%;}

.data_content ul li {float:left; width:50%; border:1px solid #fff; box-sizing:border-box; background:#dde3eb; }
 


.data_content ul li.rush { background:#ff0000 url("images/flash.png"); background-position:left center; background-repeat:no-repeat; font-weight:500; }
.data_content ul li.rush a{line-height:20px;color:#fff!important }
.data_content ul li.rush a .rush_txt{float:left; width:100%; }

.data_content ul li a{margin:0; padding:0; line-height:40px;float:left; width:100%;  }
   
.data_content ul li a.inactive{opacity:0.4}   
   
.data_content ul li a.active:hover{background:$base-blue;}
.data_content ul li a.sel{background:$base-blue ;}  
.data_content ul li.rush a.sel, .data_content ul li.rush a.active:hover{background:$base-blue url("images/flash.png"); background-position:left center; background-repeat:no-repeat; }
.tabs_consegna {
 float:left;
  position: relative;
  width: 100%;
  height:340px;
  overflow:hidden
}

.tabs_consegna nav {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 33.333%;
  
}

.tabs_consegna nav a {
  text-align: center;
  width: 100%;
  cursor: pointer;
 
  line-height:40px;
  background: #f7f7f7;
  border:1px solid #fff; 
   box-sizing:border-box;
}



.tabs_consegna nav a.rush {
 
 
 height:auto; line-height:15px; padding:5px 0px;
  background: #ff0000;
  font-weight:500;
  color:#fff; 
 
}

.note{float:left; width:100%; font-size:0.7em; margin:20px 0px 10px 0px; line-height:20px;}

.tabs_consegna nav a:hover,
.tabs_consegna nav a.selected {
  background: $base-blue;
 
}

.tabs_consegna .data_content {
  position: absolute;
  top: 0px;
  left: 33.333%;
 background:#f7f7f7;
width:0;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.1s linear 0s;
}

.tabs_consegna .data_content.visible {
  padding: 0px;
  width: 66.666%;
  opacity: 1;
}




   
   
   @media only screen and (min-width: 0px) and (max-width: 608px) {
	   .tabs_ritiro {
font-size:0.85em
}


.tabs_consegna {
font-size:0.85em
}

.data_content ul li.rush a .rush_txt{font-size:0.8em }
.data_content ul li.rush { background:#ff0000; }
.data_content ul li.rush a.sel, .data_content ul li.rush a.active:hover{background:$base-blue }
}