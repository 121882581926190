/*
* Based on Skeleton by Dave Gamache and 960 Grid
* www.getskeleton.com
*/



/* #BASE TYPOGRAPHY
================================================== */

/*
=================================================
	Basic text sizing
=================================================
	Set your main font size for paragraph

	Reference
	62.5%  => 10px
	68.8%  => 11px
	75%    => 12px
	81.3%  => 13px
	87.5%  => 14px
	100%   => 16px
	112.5% => 18px
	125%   => 20px

*/



/*
Using a Traditional scale: 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 21, 24, 36, 48, 60, 72
	if base is 16:
	h1 = 48 pixels → 48 ÷ 16 = 3 em
	h2 = 36 pixels → 36 ÷ 16 = 2.25 em
	h3 = 24 pixels → 24 ÷ 16 = 1.5 em
	h4 = 21 pixels → 21 ÷ 16 = 1.3125 em
	h5 = 18 pixels → 18 ÷ 16 = 1.125 em
	h6 = 16 pixels → 16 ÷ 16 = 1 em
*/
h1, h2, h3, h4, h5, h6 { margin: 0; padding:0; font-weight: 300; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }
h1 { font-size: 3em; line-height: 1.15em; }
h2 { font-size: 2.25em; line-height: 1.2em; }
h3 { font-size: 1.5em; line-height: 1.25em;}
h4 { font-size: 1.3125em; line-height: 1.3em; }
h5 { font-size: 1.125em; line-height: 1.35em; }
h6 { font-size: 1em; line-height: 1.4em; }







/* Table of Contents
==================================================
    #Base 960 Grid
    #Tablet (Portrait)
    #Mobile (Portrait + Landscape)




/* #Base 960 Grid
================================================== */

.container { 
	width: 1120px; 
	margin: 0 auto; 
}

.row { float:left; 
     width:100%;
	margin:10px 0px;	
}

.column, .columns { 
	float: left; 
	margin: 0 10px;
}

.container .column,
	.container .columns                         { margin-left: 10px; margin-right: 10px;  }
	.column.alpha, .columns.alpha               { margin-left: 0; margin-right: 10px; }
	.column.omega, .columns.omega               { margin-right: 0; margin-left: 10px; }
	.alpha.omega                                { margin-left: 0; margin-right: 0; }


/*****************************
16 Column
((960/16) - 20) = 40
*****************************/





/* Base Grid */
.container .one.column,
.container .one.columns                     { width: 50px;  }
.container .two.columns                     { width: 120px; }
.container .three.columns                   { width: 190px; }
.container .four.columns                    { width: 260px; }
.container .five.columns                    { width: 330px; }
.container .six.columns                     { width: 400px; }
.container .seven.columns                   { width: 470px; }
.container .eight.columns                   { width: 540px; }
.container .nine.columns                    { width: 610px; }
.container .ten.columns                     { width: 680px; }
.container .eleven.columns                  { width: 750px; }
.container .twelve.columns                  { width: 820px; }
.container .thirteen.columns                { width: 890px; }
.container .fourteen.columns                { width: 960px; }
.container .fifteen.columns                 { width: 1030px; }
.container .sixteen.columns                 { width: 1100px; }

.container .one-half.column          	    { width: 540px; } /* ((960/2) - 20) = 460 */
.container .one-third.column                { width: 353.333px; } /* ((960/3) - 20) = 300 */
.container .two-thirds.column               { width: 726.666px; } /* (((960/3) *2) - 20) = 620 */
.container .one-quarter.column              { width: 260px; } /* ((960/4) - 20) = 220 */
.container .three-quarters.column           { width: 820px; } /* (((960/4) *3) - 20) = 700 */
.container .one-fifth.column              	{ width: 204px; } /* ((960/5) - 20) = 172 */
.container .two-fifths.column              	{ width: 428px; } /* (((960/5) *2) - 20) = 364 */
.container .three-fifths.column             { width: 652px; } /* (((960/5) *3) - 20) = 556 */
.container .four-fifths.column              { width: 876px; } /* (((960/5) *4) - 20) = 748 */

/* Offsets */
.container .offset-by-one                   { padding-left: 70px;  }
.container .offset-by-two                   { padding-left: 140px; }
.container .offset-by-three                 { padding-left: 210px; }
.container .offset-by-four                  { padding-left: 280px; }
.container .offset-by-five                  { padding-left: 350px; }
.container .offset-by-six                   { padding-left: 420px; }
.container .offset-by-seven                 { padding-left: 490px; }
.container .offset-by-eight                 { padding-left: 560px; }
.container .offset-by-nine                  { padding-left: 630px; }
.container .offset-by-ten                   { padding-left: 700px; }
.container .offset-by-eleven                { padding-left: 770px; }
.container .offset-by-twelve                { padding-left: 840px; }
.container .offset-by-thirteen              { padding-left: 910px; }
.container .offset-by-fourteen              { padding-left: 980px; }
.container .offset-by-fifteen               { padding-left: 1050px; }











/*****************************
16 Column
((960/16) - 20) = 40
*****************************/
@media only screen and (min-width: 959px) and (max-width: 1200px) {
	
.container { 
	width: 960px; 
	margin: 0 auto; 
}	
	
	
/* Base Grid */
.container .one.column,
.container .one.columns                     { width: 40px;  }
.container .two.columns                     { width: 100px; }
.container .three.columns                   { width: 160px; }
.container .four.columns                    { width: 220px; }
.container .five.columns                    { width: 280px; }
.container .six.columns                     { width: 340px; }
.container .seven.columns                   { width: 400px; }
.container .eight.columns                   { width: 460px; }
.container .nine.columns                    { width: 520px; }
.container .ten.columns                     { width: 580px; }
.container .eleven.columns                  { width: 640px; }
.container .twelve.columns                  { width: 700px; }
.container .thirteen.columns                { width: 760px; }
.container .fourteen.columns                { width: 820px; }
.container .fifteen.columns                 { width: 880px; }
.container .sixteen.columns                 { width: 940px; }

.container .one-half.column          	    { width: 460px; } /* ((960/2) - 20) = 460 */
.container .one-third.column                { width: 300px; } /* ((960/3) - 20) = 300 */
.container .two-thirds.column               { width: 620px; } /* (((960/3) *2) - 20) = 620 */
.container .one-quarter.column              { width: 220px; } /* ((960/4) - 20) = 220 */
.container .three-quarters.column           { width: 700px; } /* (((960/4) *3) - 20) = 700 */
.container .one-fifth.column              	{ width: 172px; } /* ((960/5) - 20) = 172 */
.container .two-fifths.column              	{ width: 364px; } /* (((960/5) *2) - 20) = 364 */
.container .three-fifths.column             { width: 556px; } /* (((960/5) *3) - 20) = 556 */
.container .four-fifths.column              { width: 748px; } /* (((960/5) *4) - 20) = 748 */

/* Offsets */
.container .offset-by-one                   { padding-left: 60px;  }
.container .offset-by-two                   { padding-left: 120px; }
.container .offset-by-three                 { padding-left: 180px; }
.container .offset-by-four                  { padding-left: 240px; }
.container .offset-by-five                  { padding-left: 300px; }
.container .offset-by-six                   { padding-left: 360px; }
.container .offset-by-seven                 { padding-left: 420px; }
.container .offset-by-eight                 { padding-left: 480px; }
.container .offset-by-nine                  { padding-left: 540px; }
.container .offset-by-ten                   { padding-left: 600px; }
.container .offset-by-eleven                { padding-left: 660px; }
.container .offset-by-twelve                { padding-left: 720px; }
.container .offset-by-thirteen              { padding-left: 780px; }
.container .offset-by-fourteen              { padding-left: 840px; }
.container .offset-by-fifteen               { padding-left: 900px; }
}


/* #Tablet (Portrait)
================================================== */

/* Note: Design for a width of 768px */

/*****************************
16 Column
((768/16) - 20) = 28
*****************************/

@media only screen and (min-width: 768px) and (max-width: 959px) {
	.container                                  { width: 768px; }
	
	.container .one.column,
	.container .one.columns                     { width: 28px; }
	.container .two.columns                     { width: 76px; }
	.container .three.columns                   { width: 124px; }
	.container .four.columns                    { width: 172px; }
	.container .five.columns                    { width: 220px; }
	.container .six.columns                     { width: 268px; }
	.container .seven.columns                   { width: 316px; }
	.container .eight.columns                   { width: 364px; }
	.container .nine.columns                    { width: 412px; }
	.container .ten.columns                     { width: 460px; }
	.container .eleven.columns                  { width: 508px; }
	.container .twelve.columns                  { width: 556px; }
	.container .thirteen.columns                { width: 604px; }
	.container .fourteen.columns                { width: 652px; }
	.container .fifteen.columns                 { width: 700px; }
	.container .sixteen.columns                 { width: 748px; }
	
	.container .one-half.column          	    { width: 364px; } /* ((768/2) - 20) = 364 */
	.container .one-third.column                { width: 236px; } /* ((768/3) - 20) = 236 */
	.container .two-thirds.column               { width: 492px; } /* (((768/3) *2) - 20) = 492 */
	.container .one-quarter.column              { width: 172px; } /* ((768/4) - 20) = 172 */
	.container .three-quarters.column           { width: 556px; } /* (((768/4) *3) - 20) = 556 */
	.container .one-fifth.column              	{ width: 133.6px; } /* ((768/5) - 20) = 133.6 */
	.container .two-fifths.column              	{ width: 287.2px; } /* (((768/5) *2) - 20) = 287.2 */
	.container .three-fifths.column             { width: 440.8px; } /* (((768/5) *3) - 20) = 440.8 */
	.container .four-fifths.column              { width: 594.4px; } /* (((768/5) *4) - 20) = 594.4 */

	/* Offsets */
	.container .offset-by-one                   { padding-left: 48px; }
	.container .offset-by-two                   { padding-left: 96px; }
	.container .offset-by-three                 { padding-left: 144px; }
	.container .offset-by-four                  { padding-left: 192px; }
	.container .offset-by-five                  { padding-left: 240px; }
	.container .offset-by-six                   { padding-left: 288px; }
	.container .offset-by-seven                 { padding-left: 336px; }
	.container .offset-by-eight                 { padding-left: 384px; }
	.container .offset-by-nine                  { padding-left: 432px; }
	.container .offset-by-ten                   { padding-left: 480px; }
	.container .offset-by-eleven                { padding-left: 528px; }
	.container .offset-by-twelve                { padding-left: 576px; }
	.container .offset-by-thirteen              { padding-left: 624px; }
	.container .offset-by-fourteen              { padding-left: 672px; }
	.container .offset-by-fifteen               { padding-left: 720px; }
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  h1 { font-size: 2em; line-height: 1.15em; }
  h2 { font-size: 1.5em; line-height: 1.2em; }
  h3 { font-size: 1em; line-height: 1.25em;}
  h4 { font-size: 0.875em; line-height: 1.3em; }
  h5 { font-size: 0.750em; line-height: 1.35em; }
  h6 { font-size: 0.667em; line-height: 1.4em; }

  .container { width: 100%; padding: 0px 10px; box-sizing:border-box }
	.container .columns,
	.container .column { margin: 0}

	.container .one.column,
	.container .one.columns,
	.container .two.columns,
	.container .three.columns,
	.container .four.columns,
	.container .five.columns,
	.container .six.columns,
	.container .seven.columns,
	.container .eight.columns,
	.container .nine.columns,
	.container .ten.columns,
	.container .eleven.columns,
	.container .twelve.columns,
	.container .thirteen.columns,
	.container .fourteen.columns,
	.container .fifteen.columns,
	.container .sixteen.columns,
	.container .one-half.column,
	.container .one-third.column,
	.container .two-thirds.column,
	.container .one-quarter.column,
	.container .three-quarters.column, 
	.container .one-fifth.column,  
	.container .two-fifths.column,  
	.container .three-fifths.column,  
	.container .four-fifths.column { width: 100%; }

	/* Offsets */
	.container .offset-by-one,
	.container .offset-by-two,
	.container .offset-by-three,
	.container .offset-by-four,
	.container .offset-by-five,
	.container .offset-by-six,
	.container .offset-by-seven,
	.container .offset-by-eight,
	.container .offset-by-nine,
	.container .offset-by-ten,
	.container .offset-by-eleven,
	.container .offset-by-twelve,
	.container .offset-by-thirteen,
	.container .offset-by-fourteen,
	.container .offset-by-fifteen { padding-left: 0; }

.row{margin:10px 0px 0px 0px;	}
}



#scrollHP{position:fixed; bottom:140px; left:50%; width:40px; height:40px; margin:0 0 0 -20px;}
#scrollHP a span { position: absolute; top: 0; left: 50%; width: 40px;height: 40px;margin-left: -20px;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-animation: sdb04 2s infinite;
	animation: sdb04 2s infinite;
	box-sizing: border-box;
}
@-webkit-keyframes sdb04 {
	0% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
	}
	20% {
		-webkit-transform: rotate(-45deg) translate(-10px, 10px);
	}
	40% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
	}
}
@keyframes sdb04 {
	0% {
		transform: rotate(-45deg) translate(0, 0);
	}
	20% {
		transform: rotate(-45deg) translate(-10px, 10px);
	}
	40% {
		transform: rotate(-45deg) translate(0, 0);
	}
}



@media only screen and (min-width: 0px) and (max-width: 960px) {
	#scrollHP{position:fixed; bottom:40px; left:50%; width:40px; height:40px; margin:0 0 0 -20px;}
}