$fa-font-path: "~font-awesome/fonts";
@import "mixins";
@import "2021/normalize";
@import "~font-awesome/scss/font-awesome";
@import "2016/skeleton-16col";
@import "2016/vari";
@import "../../../node_modules/slick-carousel/slick/slick";
@import "../../../node_modules/scrollyeah/scrollyeah/scrollyeah";
@import "2016/main";
@import "2016/vertical-tabs";
@import "2016/checkbox";
@import "2021/webflow";
@import "2021/mamaclean-ecommerce";

#messages, #login_messages {
  width: 100%;
  z-index: 100;
  .close {
    float: right;
  }

  .info {
    background-color: $dark-blue;
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    a {
      color: $base-blue;
      text-decoration: underline;
    }
    span {
      font-size: 1.3em;
      font-weight: bold;
      color: $base-blue;
    }
  }

  .success {
    color: white;
    background-color: #393;
    border-color: #d6e9c6;
    margin: 0;
    padding-right: 10px;
    padding-left: 10px;
  }

  .warning {
    color: #333;
    background-color: #fc3;
    border-color: #d6e9c6;
    margin: 0;
    padding-right: 10px;
    padding-left: 10px;
    a {
      color: #1c2f3e;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .danger {
    color: white;
    background-color: #c03;
    border-color: #ebccd1;
    margin: 0;
    padding-right: 10px;
    padding-left: 10px;
    a {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

#invoice_is_foreign_customer {
  display: inline-block;
  width: 13px;
  line-height: 13px;
  height: 13px;
  margin-left: 5px;
}

#straniero_label {
  text-transform: uppercase;
  font-size: 0.7em;
  float: left;
  padding-bottom: 10px;
}

.close-menu {
  cursor: pointer;
  cursor: hand;
}

.tabs_ritiro {
  height: 194px;
  nav {
    input {
      display: none;
    }
    label.date {
      line-height: 41px;
    }
    label {
      font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
      text-align: center;
      text-transform: capitalize;
      width: 100%;
      cursor: pointer;
      line-height: 40px;
      background: #f7f7f7;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      color: $base-blue;

      .selected {
        background: $base-blue;
      }
    }
    label.rush {
      color: #b30000;
    }
    input[type="radio"]:checked + label {
      background: $base-blue;
      color: $darker;
    }
  }
}

.data_content ul li.rush label .rush_txt {
  color: #b30000 !important;
  margin-right: 12px;
}

.data_content ul li label .eco_txt {
  color: #59c388 !important;
  margin-right: 12px;
}

.data_content ul li label {
  margin: 0;
  padding: 0;
  line-height: 30px;
  float: left;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
}

.data_content ul {
  li {

    input {
      display: none;
    }
    label.inactive {
      opacity: 0.4
    }

    label.active:hover {
      background: $base-blue;
    }

    input[type="radio"]:checked + label {
      background: $base-blue;
    }

    input[type="radio"]:checked + label .eco_txt {
      color: #39332d !important;
    }
  }

  li.rush {
    font-weight: 500;
    background-color: #dde3eb;
    background-image: none;
  }

  li.rush label.sel, li.rush label.active:hover {
    background: $base-blue;
    background-position: left center;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 0px) and (max-width: 608px) {
  .data_content ul li.rush {

    label.sel, label.active:hover {
      background: $base-blue
    }
  }
}

//PROFILE

.blocco_ordine {
  float: left;
  width: 100%;
  margin: 0px 0px 10px 0px;
  background: #efefef;
  padding: 0px 0px 0px 0px
}

.num_ordine {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0px;
}

.riepilogo_riservata {
  float: left;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.conto_riservata {
  float: left;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 10px;
  background: #fff;
  box-sizing: border-box;
  line-height: 30px;
}

.riepilogo_riservata .conto_riservata .prodotto {
  float: left;
  width: 75%;
  text-align: left;
}

.riepilogo_riservata .conto_riservata .prezzo {
  float: left;
  width: 25%;
  text-align: right;
}

.riepilogo_riservata .conto_riservata .etichet {
  float: left;
  width: 100%;
  text-align: left;
}

.riepilogo_riservata .conto_riservata .val {
  float: left;
  width: 100%;
  text-align: left;
}

.riepilogo_riservata .conto_riservata .giorno {
  float: left;
  width: 100%;
  text-align: left;
}

.riepilogo_riservata .conto_riservata .rigat {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.riepilogo_riservata .conto_riservata .riga_finale {
  font-size: 1.2em
}

.riepilogo_riservata .conto_riservata .logistic {
  width: 50%;
}

.progress-meter {
  padding: 20px 10px 0px 10px;
  box-sizing: border-box;
  margin: 0px;
  float: left;
  width: 100%;
}

ol.progress-meter {
  list-style-type: none;
  list-style: none;
  float: left;
  width: 100%;
}

ol.progress-meter li {
  float: left;
  width: 20%;
  margin: 0;
  padding: 0;
  height: auto;

}

ol.progress-meter li span {
  float: left;
  width: 100%;
  font-size: 0.7em;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 15px;
  margin: 30px 0px 0px 0px
}

ol.progress-meter .step {
  display: inline-block;
  text-align: center;
  height: 18px;
  width: 100%;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  float: left;
}

ol.progress-meter .step:before {
  position: relative;
  float: left;
  text-indent: 0;
  left: -webkit-calc(50% - 20px);
  left: -moz-calc(50% - 20px);
  left: -ms-calc(50% - 20px);
  left: -o-calc(50% - 20px);
  left: calc(50% - 20px);
}

ol.progress-meter .step.done {

}

ol.progress-meter .step.done:before {
  font-family: 'FontAwesome';
  content: "\2711";
  height: 40px;
  width: 40px;
  line-height: 40px;
  top: 0px;
  border: none;
  border-radius: 40px;

}

ol.progress-meter li:nth-child(1) .step.done:before {
  content: "\f1d8";
}

ol.progress-meter li:nth-child(2) .step.done:before {
  content: "\f007";
}

ol.progress-meter li:nth-child(3) .step.done:before {
  content: "\f0d1";
}

ol.progress-meter li:nth-child(4) .step.done:before {
  content: "\f043";
}

ol.progress-meter li:nth-child(5) .step.done:before {
  content: "\f0d1";
}

ol.progress-meter .step.todo:before {
  content: "\2B24";
  font-size: 10px;
  top: 0;
  line-height: 40px;
  left: -webkit-calc(50% - 5px);
  left: -moz-calc(50% - 5px);
  left: -ms-calc(50% - 5px);
  left: -o-calc(50% - 5px);
  left: calc(50% - 5px);
}

ol.progress-meter .step.done {
  color: black;
  border-bottom-color: $base-blue;;
}

ol.progress-meter .step.done:before {
  color: white;
  background-color: $base-blue;
}

ol.progress-meter .step.todo {
  color: #ccc;
  border-bottom-color: #ccc;
}

ol.progress-meter .step.todo:before {
  color: #ccc;
}

.data {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
}

.operazione {
  float: left;
  width: 80%;
  margin: 0;
  padding: 0;
}

.rimanenti {
  float: left;
  width: 80%;
  margin: 0;
  padding: 0;
}

.bonus_point {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none
}

.bonus_point li {
  float: left;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #ccc;
  line-height: 30px;
}

.data {
  float: left;
  width: 15%;
  margin: 0;
  padding: 0;
}

.operazione {
  float: left;
  width: 65%;
  margin: 0;
  padding: 0;
}

.qta {
  float: left;
  width: 20%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.bonus_point li.guadagnati .qta {
  color: #090
}

.bonus_point li.spesi .qta {
  color: #ff0000
}

.totali {
  font-weight: 500;
  background: #efefef
}

.rimanenti {
  float: left;
  width: 65%;
  margin: 0;
  padding: 0;
}

.dett_ordine {
  display: none;
  cursor: pointer !important
}

.blocco_ordine.visibile .dett_ordine {
  display: block !important
}

.riepilogo_pro {
  float: left;
  width: 100%;
  background: #efefef;
  list-style: none;
  margin: 0;
  padding: 0;
}

.riepilogo_pro li {
  float: left;
  width: 100%;
  background: #efefef
}

.riepilogo_pro li .qta {
  float: left;
  width: 15%;
}

.riepilogo_pro li .dett {
  float: left;
  width: 55%;
  text-align: left;
}

.riepilogo_pro li .prez {
  float: left;
  width: 30%;
}

.riepilogo_riservata .conto_riservata .rigat .link {
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer
}

.riepilogo_riservata .conto_riservata .rigat .riepilogo_pro {
  padding-bottom: 10px;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {

  .num_ordine {
    font-size: 0.9em;
    margin-bottom: 0px;
  }

  ol.progress-meter li {
    float: left;
    width: 100%;
    margin: 0;
    padding: 10px 0px;
    height: auto;
    border-bottom: 1px solid #ccc

  }

  ol.progress-meter li span {
    float: left;
    width: 100%;
    font-size: 0.7em;
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 15px;
    margin: 0px
  }

  ol.progress-meter .step {
    display: inline-block;
    text-align: center;
    height: 30px;
    width: 100%;
    border-bottom-width: 0px;
    border-bottom-style: solid;
    float: left;
    margin-bottom: 15px
  }
}

.banner {
  display: block;
}

.field-container {
  position: relative;
  .fa {
    position: absolute;
    left: 5px;
    top: calc(50% - 0.8em); /* Keep icon in center of input, regardless of the input height */
  }
  .fa-mobile {
    top: calc(50% - 0.6em);
  }
  input {
    float: none;
    padding-left: 25px;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    margin: 0 0 10px 0;
    box-sizing: border-box;
    line-height: 36px;
    height: 36px;
    font-weight: 300;
  }
}

#invoice-is-b2b-container {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
}

.address-field {
  float: left;
  width: 75%;
}

.address-street-number {
  float: right;
  width: 24%;
}

.riepilogo_carrello {
  .conto_spedizione {
    .titolo_meta {
      position: relative;
      padding-left: 50px;
      font-weight: 700;
    }
    @extend .field-container
  }
}

input.qty {
  width: 30px;
}

input.qtypuls {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  line-height: 30px;
  font-size: 1.5em;
}

#elenco_prodotti li .descrizione .quantita input.qtypuls {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  line-height: 30px;
}

// PREZZI
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

$state-info-bg: #d9edf7 !default;
$state-success-bg: #dff0d8 !default;
$state-warning-bg: #fcf8e3 !default;
$state-danger-bg: #f2dede !default;
$body-bg: #fff !default;
$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
//** Text muted color
$text-muted: $gray-light !default;
//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px !default;

//** Default background color used for all tables.
$table-bg: transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color: #ddd !default;

// Tables

@mixin table-row-variant($state, $background) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      background-color: $background;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover > tbody > tr {
    > td.#{$state}:hover,
    > th.#{$state}:hover,
    &.#{$state}:hover > td,
    &:hover > .#{$state},
    &.#{$state}:hover > th {
      background-color: darken($background, 5%);
    }
  }
}

//
// Tables
// --------------------------------------------------

table {
  background-color: $table-bg;
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $text-muted;
  text-align: left;
}

th {
  text-align: left;
}

// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: $line-height-base;
        vertical-align: top;
        border-top: 1px solid $table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .table {
    background-color: $body-bg;
  }
}

// Condensed table w/ half padding

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid $table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    background-color: $table-bg-hover;
  }
}

// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}

table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
@include table-row-variant('active', $table-bg-active);
@include table-row-variant('success', $state-success-bg);
@include table-row-variant('info', $state-info-bg);
@include table-row-variant('warning', $state-warning-bg);
@include table-row-variant('danger', $state-danger-bg);

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: ($line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}

.riepilogo_carrello .tipo_pagamento .opzione label {
  line-height: 50px;
}

.img-payments {
  background-repeat: no-repeat;
  background-position: 0;
  float: right;
  margin-left: 10px;
  height: 50px;
}

#pag_login .dimentica_sx a, #pag_login .dimentica_dx a {
  text-decoration: underline;
}

#user_logged_in, #user_logged_out {
  font-size: 0.9em;
}

@media only screen and (max-width: 480px) {
  #app-banner {
    flex-direction: column;
  }

  #app-store-badges {
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  #appstore-badge {
    width: auto;
    height: 45px;
  }
  #playstore-badge {
    width: auto;
    height: 45px;
  }

  #messages, #login_messages {
    font-size: 0.9em;
  }
  .riepilogo_riservata .conto_riservata .logistic {
    width: 100%;
  }
  .riepilogo_carrello .conto_spedizione .titolo_meta {
    padding-left: 0;
  }
  .riepilogo_carrello .conto_spedizione .titolo_meta span {
    padding-left: 50px;
  }
}

.payment-icons {
  padding-left: 15px;
}

.riepilogo_carrello .coupon {
  line-height: 50px;
  .fa {
    top: calc(50% - 0.6em);
  }
}

.blocco_indirizzo {
  margin-bottom: 20px;
}

.riepilogo_carrello .accordion {
  padding: 10px;
}

.riepilogo_carrello .conto_spedizione .titolo_meta {
  .fa {
    position: absolute;
    left: 10px;
    top: calc(50% - 0.7em); /* Keep icon in center of input, regardless of the height */
  }
}

.accordion {
  position: relative;
  .fa {
    padding-top: 0px;
  }
}

.cella {
  textarea {
    color: #000;
    float: none;
    width: 90%;
    height: 90px;
  }
}

#order_fiscal_code {
  //text-transform: uppercase;
}

.register-form {
  .checkbox-btn {
    label {
      &::after {
        margin-left: -10px;
      }
      &::before {
        margin-left: -10px;
      }
    }
  }
}

#free_trial_banner {
  width: 100vw;
  background: url("images/pattern.png");
  background-size: 200px;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  padding: 20px 0;
  margin-bottom: 40px;
  margin-top: 40px;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mamaclean-plus-title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1em;
  text-align: center;
}
.mamaclean-plus-container {
  clear: both;
  display: flex;
  justify-content: center;
}
.mamaclean-plus-option {
  background-color:#dde3eb;
  padding: 10px;
  border-radius: 5px;
  margin: 0 10px;
}
.mamaclean-plus-selected {
  color: white;
  background-color:#66a3d4;
}
